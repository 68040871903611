import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import ProductHunt from "components/ui/base/ProductHunt";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import { IGatsbyImageData } from "gatsby-plugin-image";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";

interface VideoLiveChatPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: VideoLiveChatPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.features.video-live-chat.seo.title")}
        description={t("pages.features.video-live-chat.seo.description")}
        image={{
          relativePath: "meta/video-live-chat-for-websites.jpg",
          alt: "Video Live Chat for websites",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.features.video-live-chat.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.features.video-live-chat.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
        ]}
      />

      <PlainHeaderWithVideo
        title={t("pages.features.video-live-chat.title")}
        description={t("pages.features.video-live-chat.description")}
        ctaRegister={{ show: true }}
        descriptionMaxWidth={500}
        video={{
          path: "pages/features/video-live-chat/video-live-chat-hi.mov",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Video Live Chat",
            path: "/features/video-live-chat/",
          },
        ]}
      />

      <ProductHunt
        url={"https://www.producthunt.com/posts/video-live-chat-for-websites"}
      />

      <ImageWithDescription
        title={t("pages.features.video-live-chat.1.title")}
        description={t("pages.features.video-live-chat.1.subtitle")}
        image={{
          relativePath:
            "pages/features/video-live-chat/video-live-chat-screen-sharing.jpg",
          alt: t("pages.features.video-live-chat.1.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.video-live-chat.2.title")}
        description={t("pages.features.video-live-chat.2.subtitle")}
        image={{
          relativePath:
            "pages/features/video-live-chat/video-live-chat-engage-with-visitors.jpg",
          alt: t("pages.features.video-live-chat.2.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.video-live-chat.3.title")}
        description={t("pages.features.video-live-chat.3.subtitle")}
        image={{
          relativePath:
            "pages/features/video-live-chat/direct-video-live-chat-with-visitors-on-mobile-and-website.jpg",
          alt: t("pages.features.video-live-chat.3.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.features.video-live-chat.4.title")}
        description={t("pages.features.video-live-chat.4.subtitle")}
        id={"screen-share"}
        image={{
          relativePath:
            "pages/features/video-live-chat/screen-share-video-live-chat-zoom-alternative.jpg",
          alt: t("pages.features.video-live-chat.4.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        title={t("pages.features.video-live-chat.others.title")}
        centered={false}
        sections={[
          {
            title: t("pages.features.video-live-chat.others.helpCenter.title"),
            description: t(
              "pages.features.video-live-chat.others.helpCenter.description"
            ),
            image: {
              alt: "Help Center",
              relativePath: "icons/help-center-software.png",
            },
            links: [
              {
                text: t("pages.features.video-live-chat.others.helpCenter.cta"),
                path: "/features/help-center/",
              },
            ],
          },
          {
            title: t("pages.features.video-live-chat.others.surveys.title"),
            description: t(
              "pages.features.video-live-chat.others.surveys.description"
            ),
            image: {
              alt: "Surveys",
              relativePath: "icons/surveys.png",
            },
            links: [
              {
                text: t("pages.features.video-live-chat.others.surveys.cta"),
                path: "/features/surveys/",
              },
            ],
          },
          {
            title: t("pages.features.video-live-chat.others.funnels.title"),
            description: t(
              "pages.features.video-live-chat.others.funnels.description"
            ),
            image: {
              alt: "Funnels",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.features.video-live-chat.others.funnels.cta"),
                path: "/features/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const videoLiveChatQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/video-live-chat-for-websites.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
